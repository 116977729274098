import { BalHeading, BalText } from "@baloise/ds-react";
import { Form, FormValues } from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import {
    driversFormConfig,
    QuoteFormKey,
    quoteFormState,
    QuoteFormState,
    QuoteLayout,
    validateQuoteBusinessRules,
} from "@modules/quote";
import { Footer, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import { formatDate, getNavigationPages } from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { AppState, appState, selectAddressString } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const Drivers: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const appData = useRecoilValue<AppState>(appState);
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const addressString = useRecoilValue(selectAddressString(t)(language));
    const { getWtcPage, businessRules } = useWtc(allPages);
    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const initialFormValues: QuoteFormState = useMemo(
        () => ({
            ...formState,
            drivers: {
                ...formState?.drivers,
                usualDriver: {
                    ...formState?.drivers?.usualDriver,
                    driverDataFromSimulation: [
                        {
                            title: "all.address",
                            description: addressString,
                        },
                        {
                            title: "all.birthDay",
                            description: formatDate(appData.Driver?.birthday),
                        },
                        {
                            title: "quote.driversLicense",
                            description: formatDate(appData.Driver?.license),
                        },
                    ],
                },
            },
        }),
        // Only run this when initializing the page
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appData.Driver?.birthday, appData.Driver?.license, appData],
    );

    const handleSubmit = (values: FormValues): void => {
        setFormState(values);

        const wtcPage = getWtcPage(
            validateQuoteBusinessRules(businessRules, formState).action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }

        // check if there are more that 2 occasional drivers
        // and navigate to contact me page on the simulation app
        // to create a normal simulation lead
        if (formState?.drivers) {
            const numberOfOccasionalDrivers =
                formState?.drivers?.occasionalDrivers
                    ?.numberOfOccasionalDrivers ?? 0;

            if (numberOfOccasionalDrivers > 2) {
                const contactMePage = allPages.find(
                    (p) => p.name === PageNames.ContactMe,
                )?.paths[language];
                if (contactMePage) {
                    navigate(contactMePage);
                    return;
                }
            }
        }

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: FormValues): void => {
        setFormState(values);
    };

    return (
        <QuoteLayout
            title={t("quote.drivers.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <main className="container mt-large is-compact flex flex-direction-column">
                <div className="flex-1">
                    <div className="intro">
                        <BalHeading level="h1">
                            {t("quote.drivers.title")}
                        </BalHeading>
                        <BalText>{t("quote.drivers.intro")}</BalText>
                    </div>
                    <Form
                        scrollToFieldError
                        errorMessageCardTitle={
                            t("all.errormessage.title") as string
                        }
                        errorMessageCardSubTitle={
                            t("all.errormessage.text") as string
                        }
                        formContext={{
                            t,
                            nameSpace: QuoteFormKey.Drivers,
                            fieldWrapper: {
                                optionalLabel: "all.optional",
                            },
                        }}
                        initialValues={initialFormValues}
                        onSubmit={handleSubmit}
                        onChange={handleOnChange}
                        fields={driversFormConfig.fields}
                    >
                        <Navigation
                            t={t}
                            language={language}
                            prevPage={prevPage}
                            nextPage={nextPage}
                        />
                    </Form>
                </div>
                <Footer />
            </main>
        </QuoteLayout>
    );
};

export default Drivers;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
